import React, { useEffect } from 'react'
import { Router, navigate } from '@reach/router'

import {
  TeamScreen,
  HorseSearch,
  HorseView,
  AthleteSearch,
  AthleteView,
  Login,
  ResultsView,
  CompetitionView,
  ZoneAnalysis,
  TargetEventPlanner,
  TeamSim,
  EloRatings,
} from '../screens'

const Navigation = () => {
  return (
    <>
      <Router>
        <Login path="/login" default />
        <TeamScreen path="/team" />
        <HorseSearch path="/horses" />
        <HorseView path="/horses/:horseId" />
        <AthleteSearch path="/athletes" />
        <AthleteView path="/athletes/:athleteId" />
        <ResultsView path="/venues" />
        <CompetitionView path="/venues/competition/:compId" />
        <ZoneAnalysis path="/zone_analysis" />
        <TargetEventPlanner path="/target_event_planner" />
        <TeamSim path="/team_simulator" />
        <EloRatings path="/elo_ratings" />
      </Router>
    </>
  )
}

export default Navigation
