import React from 'react'
import { Layout } from '../components'
import { Widget } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css'

class TargetEventPlanner extends React.Component {
    render() {
        const isAthlete = localStorage.getItem('hpAthleteId')
        const hpNationality = localStorage.getItem('hpNationality')

        return (
            <Layout>
                {isAthlete !== 'null' && hpNationality === 'USA'
                    ? (
                        <Widget
                            id="oG5frOYg"
                            style={{
                                height: '800px',
                                margin: '0 auto',
                                backgroundColor: 'transparent',
                            }}
                        />
                    )
                    : null
                }
            </Layout>
        )
    }
}

export default TargetEventPlanner
