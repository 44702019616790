import fei from '../assets/images/FEI logo copy.png'
import ea from '../assets/images/Equestrian-Australia.png'
import be from '../assets/images/British_Eventing.jpg'
import usef from '../assets/images/us_equestrian_logo_50.jpg'
import ei from '../assets/images/eiLogo.png'
import nzl from '../assets/images/NZL.png'

export const formatStat = (stat, toDecimal = 2) => {
  if (stat == 0.0) return 0
  if (stat) return parseFloat(stat).toFixed(toDecimal)
  return '-'
}

export const formatToPercentage = (number, dec = 1) => {
  if (number == 0.0) return '0%'
  if (number) {
    let parsedNum = parseFloat(number) * 100
    return `${parsedNum.toFixed(dec)}%`
  }
  return '-'
}

export const formatHorseName = (string) => {
  let splitString = string.toLowerCase().split(' ')
  const formattedString = splitString
    .slice(0, 2)
    .map((item) => (item = item.charAt().toUpperCase() + item.substring(1)))
  return formattedString.join(' ')
}

export const getProviderImage = () => {
  const hpNationality = localStorage.getItem('hpNationality')
  switch (hpNationality) {
    case 'NZL':
      // const fei = require('../assets/images/FEI logo copy.png')
      return <img src={nzl} height="40px" width="80px" />
    case 'AUS':
      // const ea = require('../assets/images/Equestrian-Australia.png')
      return <img src={ea} height="30px" width="100px" />
    case 'GBR':
      // const be = require('../assets/images/British_Eventing.jpg')
      return <img src={be} height="30px" width="100px" />
    case 'USA':
      return <img src={usef} />
    case 'IRL':
      return <img src={ei} height="35px" width="60px" />
    default:
      // const ei = require('../assets/images/eiLogo.png')
      return null
  }
}
