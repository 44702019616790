import React, { useState } from 'react'
import styled from 'styled-components'
import Text from './Text'
import Spacer from './Spacer'
import { theme } from '../config'
import { zoneAnalysisAuth } from '../utilities/zoneAnalysisAuth'
import { teamPerfAuth } from '../utilities/teamPerfAuth'
import { navigate } from '@reach/router'

const Container = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	@media (${theme.breakpoints.mediumAndUp}) {
		width: 95%;
	}
`
const Tab = styled.div`
	margin-right: ${theme.spacing.x4};
	margin-top: ${theme.spacing.x2};
	margin-bottom: ${theme.spacing.x2};
	padding: ${theme.spacing.x2} ${theme.spacing.x6};
	border-radius: ${({ active }) => (active ? theme.radii.x2 : 'none')};
	background: ${({ active }) => (active ? theme.colors.white100 : 'none')};
	box-shadow: ${({ active }) => (active ? theme.boxShadow : 'none')};
`

const NavigationTab = () => {
  const [activeTab, setActiveTab] = useState(
    parseInt(localStorage.getItem('navTab'), 10) || 0
  )

  const handleTabClick = async (tab, url, state = {}) => {
    await localStorage.setItem('navTab', tab)
    await setActiveTab(tab)
    navigate(url, state)
  }

  const isAthlete = localStorage.getItem('hpAthleteId')
  const hpNationality = localStorage.getItem('hpNationality')

  return (
    <>
      <Container>
        <Tab
          active={activeTab === 0}
          onClick={() => handleTabClick(0, '/team')}
        >
          <Text size="x4" weight="semibold" cursor={'pointer'}>
            {isAthlete == 'null' ? 'My Team' : 'My Overview'}
          </Text>
        </Tab>
        <Tab
          active={activeTab === 1}
          onClick={() => {
            handleTabClick(1, '/horses')
          }}
        >
          <Text size="x4" weight="semibold" cursor={'pointer'}>
            My Horses
          </Text>
        </Tab>

        {isAthlete == 'null' ? (
          <Tab
            active={activeTab === 2}
            onClick={() => handleTabClick(2, '/athletes')}
          >
            <Text size="x4" weight="semibold" cursor={'pointer'}>
              My Athletes
            </Text>
          </Tab>
        ) : (
          <Tab
            active={activeTab === 2}
            onClick={() =>
              handleTabClick(2, `/athletes/${isAthlete}`, { id: isAthlete })
            }
          >
            <Text size="x4" weight="semibold" cursor={'pointer'}>
              My Results
            </Text>
          </Tab>
        )}
        <Tab
          active={activeTab === 3}
          onClick={() => handleTabClick(3, '/venues')}
        >
          <Text size="x4" weight="semibold" cursor={'pointer'}>
            Venues
          </Text>
        </Tab>
        {isAthlete == 'null' && (
          <Tab
            active={activeTab === 4}
            onClick={() => handleTabClick(4, '/elo_ratings')}
          >
            <Text size="x4" weight="semibold" cursor={'pointer'}>
              Elo Ratings
            </Text>
          </Tab>
        )}
        <Tab
          active={activeTab === 5}
          onClick={() => {
            zoneAnalysisAuth()
              .then((response) => handleTabClick(5, '/zone_analysis'))
              .catch((error) => console.log(error))
          }}
        >
          <Text size="x4" weight="semibold" cursor={'pointer'}>
            Zone Analysis
          </Text>
        </Tab>
        {isAthlete == 'null' && (
          <Tab
            active={activeTab === 6}
            onClick={() => {
              teamPerfAuth()
                .then((response) => handleTabClick(6, '/team_simulator'))
                .catch((error) => console.log(error))
            }}
          >
            <Text size="x4" weight="semibold" cursor={'pointer'}>
              Team Selector
            </Text>
          </Tab>
        )}
        {isAthlete !== 'null' && hpNationality === 'USA' && (
          <Tab
            active={activeTab === 7}
            onClick={() => handleTabClick(7, '/target_event_planner')}
          >
            <Text size="x4" weight="semibold" cursor={'pointer'}>
              Target Event Planner
            </Text>
          </Tab>
        )}
      </Container>
    </>
  )
}

export default NavigationTab
