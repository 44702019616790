import React, { useMemo } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { navigate } from '@reach/router'
import { theme } from '../config'
import Table from './Table'
import HStack from './HStack'
import Question from '../assets/icons/Question'
import Spacer from './Spacer'
import Text from './Text'
import Dropdown from './Dropdown'
import MultiSelectDropdown from './MultiSelectDropdown'
import { formatStat } from '../utilities/helperFunctions'
import envVars from '../utilities/envVars'

const TitleContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`

const DropdownContainer = styled.div`
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const TeamExpecteds = ({
	data,
	setYearFrom,
	setYearTo,
	setLevel,
	selectedLevels,
	yearFromDropdownInitialItem,
	yearToDropdownInitialItem,
}) => {
	const newData = useMemo(() => data && data.data, [data])

	const majorClasses = envVars.majorClasses.map((lvl) => {
		return { title: lvl.key, level: lvl.value };
	});

	const columns = useMemo(
		() => [
			{
				Header: 'Horse',
				textAlign: 'left',
				accessor: 'attributes.horse',
				isBold: true,
				cursor: 'pointer',
				Cell: (props) => (
					<div
						style={{
							cursor: 'pointer',
							textDecoration: 'underline',
						}}
						onClick={() => {
							localStorage.setItem('navTab', 1)
							navigate(`/horses/${props.row.original.attributes.horseId}`, {
								state: {
									id: props.row.original.attributes.groupById,
									name: props.value,
									athlete: props.row.original.attributes.currentHpAthlete,
									elo: props.row.original.attributes.currentElo,
								},
							})
						}}
					>
						{props.value}
					</div>
				),
			},
			{
				Header: ' ',
				columns: [
					{
						Header: 'Class',
						accessor: 'attributes.class',
					},
				],
			},
			{
				Header: 'Dressage',
				columns: [
					{
						Header: 'Expected DR',
						accessor: 'attributes.drScore',
					},
				],
			},
			{
				Header: 'Show Jumping',
				columns: [
					{
						Header: 'SJ Expected',
						accessor: 'attributes.sjScore',
						Cell: (props) => <div>{props.value}</div>,
					},
				],
			},
			{
				Header: 'Cross Country',
				columns: [
					{
						Header: 'XCJ Expected',
						accessor: 'attributes.xcjClear',
						Cell: (props) => <div>{props.value}</div>,
					},
					{
						Header: 'XC Time',
						accessor: 'attributes.xcTime',
						Cell: (props) => <div>{props.value}</div>,
					},
				],
			},
			{
				Header: 'Elo',
				columns: [
					{
						Header: 'Current',
						accessor: 'attributes.currentElo',
						Cell: (props) => <div>{props.value}</div>,
					},
					{
						Header: 'Max',
						accessor: 'attributes.maxElo',
						Cell: (props) => <div>{props.value}</div>,
					},
				],
			},
		],
		[]
	)

	return (
		<>
			<TitleContainer>
				<HStack align="center">
					<Text size="x5" weight="semibold">
						Horse Expecteds
					</Text>
					<Spacer width="x4" />
					<Question data-tip data-for="teamOverview" />
				</HStack>
				<DropdownContainer>
					<Spacer width="x4" />
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text size="x4" weight="semibold">
							Year From
						</Text>
						<Spacer height="x1" />
						<Dropdown
							onChange={(year) => setYearFrom(year)}
							readOnly={true}
							items={envVars.years}
							itemToString={(year) => year}
							initialItem={yearFromDropdownInitialItem}
							handleFilter={() => () => true}
							displaySuggestion={(year) => (
								<Text
									size={theme.fontScale.x2}
									weight={theme.fontWeight.normal}
								>
									{year}
								</Text>
							)}
						/>
					</div>
					<Spacer width="x4" />
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text size="x4" weight="semibold">
							Year To
						</Text>
						<Spacer height="x1" />
						<Dropdown
							onChange={(year) => setYearTo(year)}
							readOnly={true}
							items={envVars.years}
							itemToString={(year) => year}
							initialItem={yearToDropdownInitialItem}
							handleFilter={() => () => true}
							displaySuggestion={(year) => (
								<Text
									size={theme.fontScale.x2}
									weight={theme.fontWeight.normal}
								>
									{year}
								</Text>
							)}
						/>
					</div>
					<Spacer width="x4" />
					<div
						style={{ width: '70%', display: 'flex', flexDirection: 'column' }}
					>
						<Text size="x4" weight="semibold">
							Select Level
						</Text>
						<Spacer height="x1" />
						<Dropdown
							onChange={(item) => setLevel(item.level)}
							readOnly={true}
							items={majorClasses}
							itemToString={(level) => level.title}
							initialItem={majorClasses[0]}
							handleFilter={() => () => true}
							displaySuggestion={(level) => (
								<Text
									size={theme.fontScale.x2}
									weight={theme.fontWeight.normal}
								>
									{level.title}
								</Text>
							)}
						/>
					</div>
				</DropdownContainer>
			</TitleContainer>
			<Spacer height="x4" />
			<Table
				columns={columns}
				data={newData}
				defaultSort={'attributes.powerAverage'}
				desc={false}
			/>
		</>
	)
}

export default TeamExpecteds
